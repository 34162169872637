.footer {
  margin-top: auto;
  padding: 60px 0 50px;
  background-color: #171717;
  color: #fff;
}
.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 27px;
}
.footer__list {
  display: flex;
  align-items: center;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
.footer__copyright {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
