.nav {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #26292d;
  background-color: #171717;
  letter-spacing: normal;
  z-index: 10;
}
.nav__row {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.nav__logo {
  margin-right: auto;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
}
.nav__logo span {
  font-weight: 700;
}
.nav__list {
  display: flex;
  align-items: center;
  -moz-column-gap: 40px;
  column-gap: 40px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.nav__link {
  color: #fff;
  position: relative;
  
}
.nav__link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  width: 100%;
  height: 3px;
  background: #5c62ec;
  scale: 0;
  transition: all 0.3s ease-in;
}
.nav__link:hover::after {
  scale: 1;
}
.nav__link_active {
  position: relative;
}
.nav__link_active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  width: 100%;
  height: 3px;
  background: #5c62ec;
}

.hamburger {
  cursor: pointer;
  height: 21px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}
.hamburger div {
  transition: all 0.2s;
  width: 30px;
  height: 3px;
  background: #fff;
}
.hamburger_active div {
  margin-bottom: -2px;
}
.hamburger_active div:nth-child(1) {
  transform: translateY(9px) rotate(-45deg);
}
.hamburger_active div:nth-child(2) {
  display: none;
}
.hamburger_active div:nth-child(3) {
  transform: translateY(-11px) rotate(45deg);
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 5;
}
.overlay.active {
  display: flex;
}
